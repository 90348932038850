import * as React from "react"
import SiteContainer from "../../components/site-container";
import { Link } from "gatsby";
import PricingSection from "../../components/PricingSection";
import { Helmet } from "react-helmet";

export function Pricing() {
  return (
    <div>
      <Link to="/pricing/">Pricing</Link>
    </div>
  )
}

export function SignupPage() {
  return (
    <div>
      <Link to="https://app.firstpartyhq.com/authentication/signup">Signup</Link>
    </div>
  )
}


const PricingPage = () => {
  return (
    <SiteContainer>
      <Helmet>
        <title>Firstparty - Pay-as-you-go Pricing</title>
        <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎉</text></svg>" />
      </Helmet>
      <PricingSection />
    </SiteContainer>
  )
}

export default PricingPage